/* Login.module.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  & ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  & .contentWrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    /* height: 100vh; */
    max-width: 1920px;
    margin: 0 auto;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      height: auto;
    }
    & .leftImage {
      width: 43%;
      @media (max-width: 767px) {
        width: 100%;
      }
      & .merchantBannerImage {
        width: 100%;
        height: 100vh;
        object-fit: contain;
        object-position: top left;
        position: sticky;
        top: 0;
      }
    }
    & .rightContent {
      width: 57%;
      @media (max-width: 767px) {
        width: 100%;
      }
      & .logo {
        width: 90px;
        height: 90px;
        margin: 0 0px 18px auto;
        text-align: right;
        display: block;
        @media (max-width: 767px) {
          margin: 0 0px 9px auto;
        }
      }
      & form {
        height: auto;
      }
    }
    & .authInputs {
      max-width: calc(570px + 40px);
      /* height: 100vh;
      overflow-y: auto;
      overflow-x: hidden; */
      margin: 0 auto;
      padding: 39px 20px;
      @media (max-width: 767px) {
        height: auto;
      }
    }
    & .loginForm {
      & input {
        border: unset;
        height: 68px;
        background-color: #dadada47;
        border-radius: 10px;
        @media (max-width: 767px) {
          height: 58px;
        }
      }
      & > div {
        & > p {
          display: none;
        }
        &:nth-child(2) {
          margin-top: 36px;
        }
        & button {
          min-height: 53px;
          background-color: #f4b31b;
          font-family: Inter;
          font-size: 18px;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: -0.02em;
          text-align: center;
          border-radius: 50px;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #111111;
        }
      }
    }
  }

  & .loginTitle {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    margin: 0 0 79px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  & .signupTitle {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    margin: 0 0 34px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }

  & .loginButton {
    width: 100%;
    padding: 12px;
    background-color: #f6a82f;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
  }


}
