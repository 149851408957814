.arabicDirection {
  direction: rtl;

  & .bookingDetails {
    @media (max-width: 1023px) {
      padding-right: 0px !important;
      padding-left: 40px;
    }
  }

  & .poweredByBanner {
    left: 0;
    right: unset !important;

    & .poweredText {
      font-size: 10px;
    }

    & .backIcon {
      z-index: 0;

      & svg {
        transform: rotateY(180deg);
      }
    }

    & .bannerContent {
      left: 6px;
    }
  }
}

.bookingDetails {
  display: flex;
  gap: 11px;
  margin: 0 0 28px;

  @media (max-width: 1023px) {
    padding-right: 40px;
  }

  & .userImage {
    width: 48px;
    height: 53px;
    object-fit: cover;
    border-radius: 18px;
  }

  & .title {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000;
    margin: 0 0 2px;
    @media (min-width: 1024px) {
     padding-right: 20px;
    }
  }

  & .detailsText {
    font-family: Inter;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000;

    & .address {
      font-family: Inter;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #000;
      margin: 0;
      padding: 0;
    }
  }
}
.detailsBooking{
  flex: 1;
}
.poweredByBanner {
  position: absolute;
  right: 0;
  top: 0;
  @media (min-width: 1024px) {
    top: 27px;
  }

  & .poweredText {
    /* font-family: SF Pro Text; */
    font-weight: 700;
    font-size: 7px;
    line-height: 8.35px;
    letter-spacing: 0px;
    text-align: center;
    margin: 15px 0 4px;
    padding: 0;
  }

  & .bannerContent {
    position: absolute;
    right: 6px;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.bookOnWhatsapp {
  /* font-family: SF Pro Text; */
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  
  color: #32d851;

  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    
  }
}
